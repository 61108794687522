<template>
  <form @submit.prevent="addTechno">
    <label for="techno">Ajouter une tâche :</label>
    <input type="text" name="techno" v-model="techToLearn" required/>
    <button type="submit">&#10132;</button>
  </form>
</template>

<script>
import { ref } from "vue";
export default {
  setup(props, ctx) {
    let techToLearn = ref("");
    const addTechno = function () {
      ctx.emit("add", techToLearn.value);
      techToLearn.value = "";
    };
    return {
      techToLearn,
      addTechno,
    };
  },
};
</script>

<style>
form {
  width: 100%;
  max-width: calc(100% - 32px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 16px auto;
}
form > * {
  line-height: 2;
  border: none;
  padding: 4px 6px;
  outline: none;
  font-family: monospace;
}
form > label {
  width: 100%;
}
form > input,
form > button {
  font-size: 18px;
}
form > input:not([type="submit"]) {
  background-color: #415c76;
  color: #fff;
  box-shadow: inset 0px -2px 0px 0px #53dfc1;
  transition: background-color 0.125s ease-out, box-shadow 0.125s ease-out;
}
form > input:not([type="submit"]):focus {
  background-color: hsl(209, 29%, 42%);
  box-shadow: inset 0px -2px 0px 0px #349882;
}
form > input[type="submit"],
form > button[type="submit"] {
  width: max-content;
  min-width: 32px;
  max-width: 250px;
  padding: 4px 8px;
  background-color: #21b595;
  color:#fff;
  font-weight:900;
  transition:background-color .125s ease-out;
}
form > input[type="submit"]:hover,
form > button[type="submit"]:hover {
  cursor: pointer;
  background-color: #26cfaa;
}
form > input[type="submit"]:active,
form > button[type="submit"]:active {
  cursor: pointer;
  background-color: #167963;
}
</style>