<template>
  <h1>À faire</h1>
  <Form @add="saveTechno" />
  <p class="mentions-webdevoo">Ce service vous est offert par <a href="https://webdevoo.com" target="_blank" rel="sponsored">Webdevoo, concepteur de sites web</a>, la liste sera enregistrée jusqu'à l'extinction de votre appareil.</p>
  <TechnoList
    :technos="technos"
    @delete-techno="deleteTechno"
    @validate-techno="validateTechno"
    @edit-techno="editTechno"
    @save-list="saveList"
    @save-edit="saveEdit"
  />
</template>

<script>
import Form from "@/components/Form.vue";
import TechnoList from "@/components/TechnoList.vue";
import { ref } from "vue";

export default {
  name: "App",
  components: {
    Form,
    TechnoList,
  },
  setup() {
    let technos = ref([]);
    let listeSave = ref([]);
    if (localStorage.getItem("saveList")) {
      let localList = JSON.parse(localStorage.getItem("saveList"));
      localList.forEach((el) => {
        if (listeSave.value.indexOf(el.techno) < 0) {
          listeSave.value.push(el.techno);
          technos.value = [
            ...technos.value,
            {
              techno: el.techno,
              id: el.id,
              validate: false,
            },
          ];
        }
      });
    }

    const saveTechno = function (data) {
      if (listeSave.value.indexOf(data) < 0) {
        listeSave.value.push(data);
        technos.value = [
          ...technos.value,
          {
            techno: data,
            id: Date.now(),
            validate: false,
          },
        ];
        saveList(technos.value);
      }
      // technos.value.forEach(tech=>{
      //   if(listeSave.value.indexOf(tech.techno) < 0){
      //     listeSave.value.push(tech.techno);
      //     console.log(listeSave.value);
      //   }
      // });
    };
    const deleteTechno = function (tech) {
      if (window.confirm("Êtes-vous sûr de vouloir supprimer cette tâche ?")) {
        technos.value = technos.value.filter((t) => t.techno !== tech.techno);
        localStorage.setItem("saveList", JSON.stringify(technos.value));
        listeSave.value.forEach((el, index)=>{
          if(el == tech.techno){
            listeSave.value.splice(index,1);
          }
        })
      }
    };
    const editTechno = function (tech) {
      if (listeSave.value.indexOf(tech.techno) < 0) {
        listeSave.value.push(tech.techno);
        technos.value = technos.value.map((t) => (t.id !== tech.id ? t : tech));
      }
    };
    //validateTechno = function(tech)
    const validateTechno = function () {
      // console.log("App | validate_techno() | tech : ", tech.validate);
    };
    const saveEdit = function (tech) {
      if (listeSave.value.indexOf(tech.techno) < 0) {
        listeSave.value.push(tech.techno);
        technos.value = technos.value.map((t) => {
          if (t.techno !== tech.techno) {
            return t;
          } else {
            return tech;
          }
        });
        // technos.value = technos.value.map((t) => (t.techno !== tech.techno ? t : tech));
        saveList(technos.value);
      }
    };
    const saveList = function (liste) {
      // localStorage.setItem('saveList', JSON.stringify(listeSave.value));
      localStorage.setItem("saveList", JSON.stringify(technos.value));
      // console.log(localStorage.getItem("saveList"));
      console.log("App | saveList() | liste :", liste);
    };
    return {
      saveTechno,
      deleteTechno,
      validateTechno,
      editTechno,
      saveList,
      saveEdit,
      technos,
    };
  },
};
</script>

<style>
*,
::before,
::after {
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  word-break: break-word;
}
body {
  background-color: #2c3e50;
}
ul,
ol {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  list-style: none;
  gap: 6px;
}
a,
button,
input[type="submit"] {
  cursor: pointer;
}
a,
a:visited{
  text-decoration:none;
  box-shadow:0px 1px 0px 0px #fff;
  color:#fafafa;
  transition:color .125s ease-out, box-shadow .125s ease-out;
}
a:hover{
  color:#21b595;
  box-shadow:0px 1px 0px 0px #21b595;
}
.mentions-webdevoo{
  max-width:720px;
  margin:0 auto;
  font-size:13px;
  font-family:monospace;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #dce5ee;
  margin-top: 60px;
}
@media only screen and (max-width:720px){
  .mentions-webdevoo{
    max-width:330px;
  }
}
</style>
