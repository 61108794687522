<template>
  <button
    @click="saveList"
    class="save-list-btn"
  >
    Sauvegarder la liste
  </button>
  <section>
    <h2>Liste des tâches</h2>
    <p>{{ technos.length }} tâche{{ technos.length > 1 ? "s" : "" }}</p>
    <ul>
      <li v-for="tech in technos" :key="tech.id">
        <span v-if="technoToEdit !== null && technoToEdit.id === tech.id">
          <!--
          <input
            type="text"
            v-model="technoToEdit.techno"
            @keypress.enter="save"
            required/>            
           -->
          <textarea class="edit-area" v-model="technoToEdit.techno" @keypress.enter="save"></textarea>
        </span>
        <span
          v-else
          class="taskText"
          :class="{ validateTask: tech.validate }"
          >{{ tech.techno }}</span
        >
        <div class="control-btn-container">
          <button
            @click="validateTechno(tech)"
            class="validate-btn"
            :disabled="technoToEdit !== null && technoToEdit.id === tech.id"
          >
            &check;
          </button>
          <button
            v-if="technoToEdit !== null && technoToEdit.id === tech.id"
            @click="save"
            class="edit-btn-save"
          >
            &check;
          </button>
          <button v-else @click="editTechno(tech)" class="edit-btn">
            &#9998;
          </button>
          <button
            @click="deleteTechno(tech)"
            class="delete-btn"
            :disabled="technoToEdit !== null && technoToEdit.id === tech.id"
          >
            &cross;
          </button>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import { ref } from "vue";
export default {
  emits: ["delete-techno", "validate-techno", "edit-techno", "save-list", "save-edit"],
  props: {
    technos: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    let technoToEdit = ref(null);
    let saveTechnoEdit = ref([]);
    let listeTechnos = ref([]);

    const deleteTechno = function (tech) {
      emit("delete-techno", tech);
    };
    const validateTechno = function (tech) {
      if (tech.validate) {
        tech.validate = false;
      } else {
        tech.validate = true;
      }
      emit("validate-techno", tech);
    };
    const editTechno = function (tech) {
      technoToEdit.value = tech;
      emit("edit-techno", technoToEdit.value);
    };
    const save = function () {
      console.log(saveTechnoEdit.value);
      emit("save-edit", technoToEdit.value);
      technoToEdit.value = null;
    };
    const saveList = function () {
      let liste = document.querySelectorAll('li>span.taskText');
      liste.forEach(el=>{
        listeTechnos.value = [...listeTechnos.value, el.textContent];
      })
      emit("save-list", listeTechnos.value);
    };
    return {
      deleteTechno,
      validateTechno,
      editTechno,
      technoToEdit,
      save,
      saveList,
    };
  },
};
</script>

<style>
section {
  width: 100%;
  margin: 32px auto;
}
ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  list-style: none;
  gap: 8px;
  padding:12px 8px;
}
li {
  /* width: max-content; */
  width:100%;
  background-color:hsl(210, 29%, 28%);
  padding: 4px 6px;
  text-align: left;
  position:relative;
  box-shadow:0px 0px 1px 0px hsl(210, 29%, 58%);
}
.taskText {
  padding: 4px 8px;
  font-size:24px;
}
li::before {
  content: "→";
  width: max-content;
  padding: 0 6px;
}

li > .control-btn-container {
  width: max-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  background-color: #417676;
  padding: 3px;
  margin-left:auto;
  opacity:0.05;
}

li:hover > .control-btn-container,
li:focus > .control-btn-container{
  opacity:1;
}

li > .control-btn-container > button {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: monospace;
  border: none;
  background-color: #509191;
  color: #fff;
  font-size: 18px;
  font-weight: 900;
  transition: background-color 0.125s ease-out;
}
li > .control-btn-container > button:hover {
  background-color: #417676;
}
li > .control-btn-container > button[disabled] {
  background-color: #1d3535;
  opacity: 0.2;
}
li > .control-btn-container > button.delete-btn {
  background-color: #aa0e20;
}
li > .control-btn-container > button.delete-btn:hover {
  background-color: #d91229;
}
li > .control-btn-container > button.edit-btn {
  background-color: #325d5d;
}
li > .control-btn-container > button.edit-btn:hover {
  background-color: #6bc2c2;
}
li > .control-btn-container > button.validate-btn {
  background-color: #325c5c;
}
li > .control-btn-container > button.validate-btn:not([disabled]):hover {
  background-color: #6bc2c2;
}
.validateTask {
  text-decoration: line-through;
}
li > .control-btn-container > button.edit-btn-save {
  background-color: #c29b57;
  padding-left: 24px;
  padding-right: 24px;
}
.save-list-btn {
  width: max-content;
  max-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 12px auto;
  padding: 6px 8px;
  background-color: #417676;
  font-weight: 500;
  font-family: system-ui;
  color: #fff;
  border: none;
  box-shadow: inset 0 0 0 2px #417676;
}
.save-list-btn:hover {
  background-color: #325c5c;
  box-shadow: inset 0 0 0 1px #417676;
}
.save-list-btn:active {
  background-color: #325c5c;
  box-shadow: inset 0 0 0 2px #2c3e50;
}
.edit-area{
  min-width:350px;
  max-width:calc(100% - 64px);
  min-height:40px;
  height:auto;
  aspect-ratio:4/1;
  max-height:240px;
  width:100%;
  line-height:2;
  resize:none;
  padding:6px 8px;
  border:none;
  background-color:#2c3e50;
  color:#d0c4ae;
  font-size:16px;
}
</style>